//zh 中文语言包
export default {
    buttons: {
      addToDirectory: '登入名簿',
      removeFromDirectory: '從名簿移除',
      viewMap: '地圖檢視',
      shareCard: '分享名片',
      showQRCode: '顯示QR碼',
    },
    contact: {
      clickableEmail: '可點擊的email連絡方式：',
      contactMethod: '連絡方式：',
      availableTime: '有空時間：',
      personalWebsite: '個人網址',
      communityWebsite: '社群網址',
    },
    card: {
      interests: '興趣： ',
      shareable: '可分享： ',
      seeking: '尋找： ',
      payable: '可支付： ',
    },
    login:{
      alreadyUpdated: '已更新',
      login_into_your_account: '登入您的帳戶',
      login_with_google: '以 Google 帳號登入',
      keep_me_logged_in: '維持登入狀態',
      forgot_password: '忘記密碼?',
      or: '或',
      small_hint: '小秘訣：',
      welcome:'歡迎來到自學2.0公益尋友平台',
      hp: '首頁',
      ab: '關於我們',
      link: '友站連結',
      mp: '地圖',
      fr: '夥伴',
      polis: '論壇',
      gp: '社團',
      auto_gp: '自學社團',
      fg: '我的旗幟',
      hd: '聞道有先後，術業有專攻',
      pr: ' 隱私權政策 ',
      bk: '我的名簿',
      logout: '登出',
      lm: '瞭解更多',
      login: '登入',
      login_to_see_data: '登入以檢視內容',
      login_sucess: '登入成功',
      login_first: '請先登入',
      go_flag: '前往我的旗幟',
      em: 'Email: ',    
      isapp: '本系統不支援Facebook, Line等App內部瀏覽，請用一般瀏覽器開啟，方可登入，謝謝',
      old: '歲',
      arround: '大約',
      raise_flag: '升起旗幟!!',
      update_flag: '更新旗幟!!',
      raise_sucess: '升起旗幟成功',
      update_sucess: '更新成功',
      update_failed: '更新失敗',
      check_flag: '查看專屬名片',
      delete_flag: '刪除旗幟',
      agree_pr: '請先勾選「我同意自學2.0的隱私權政策」',
      delete_confirm2: '確認您要退出自學2.0並刪除您的互助旗嗎？提醒您，此動作無法恢復',
      delete_confirm3: '您的互助旗幟已刪除。謝謝您的使用。',
      delete_failed: '刪除失敗',
      anoymous: '匿名',
      end: '結束',
      leave_messages: '留言',
      source_github: '原始碼',
    },
    tutorial: {
      新手任務: '新手任務',
      任務: '任務',
      隱藏新手任務: '隱藏新手任務',
      升起互助旗: '升起互助旗',
      將一位夥伴加入名簿: '將一位夥伴加入名簿',
      加入或創建一個社團: '加入或創建一個社團', 
      在社團中推薦一個資源: '在社團中推薦一個資源',
    },
    profile: {
      announce1: '請先詳閱我們的',
      announce2: '隱私權政策',
      announce3: '',
      push: '按此',
      start: '開始',
      edit: '編輯',
      update1: '請立即更新',
      update2: '您的資料已三個月未更新，請立即更新，謝謝',
      fillform: '請填表',
      form1: '為了讓人真的能找到夥伴',
      form2: '有標記紅色星號',
      form3: '的欄位是必填的',
      form4: '關於我',
      form5: '名字',
      form6: '您的大名',
      form7: '概略地址',
      form8: '地址愈詳細，別人愈好認識你的所在',
      form9: '無法定位',
      form10: '如果您有不只一個住處，請填寫一個就好，其他可寫進自我介紹中。',
      form11: '若持續無法定位，請',
      form12: '使用Chrome瀏覽器',
      form13: '再試一次。',
      form14: '地址太詳細了',
      form15: '這是公開資料，最細寫到路段即可，請不要寫出門牌號碼。',
      form16: '手動拖拉標記，移到您的概略位置',
      form17: '經緯座標',
      form18: '網址',
      form19: '個人網址',
      form20: '測試連結',
      form21: '參與的社群網址',
      form22: '社群網址',
      form23: '有效聯絡方式',
      form24: '可公開的方式，如e-mail。臉書私訊不可公開。',
      form25: '比較有空的時段',
      form26: '例如：週五下午和週末',
      form27: '社交資訊',
      form28: '您的身份',
      form29: '選擇一種自學身份',
      form30: '自學生',
      form31: '自學家長',
      form32: '獨立教育工作者',
      form33: '自學家長 + 獨立教育工作者',
      form34: '自學生 + 獨立教育工作者',
      form35: '自學生 + 自學家長',
      form36: '自學生 + 自學家長 + 獨立教育工作者',
      form37: '主要的自學型態',
      form38: '選擇一種自學型態',
      form39: '個人',
      form40: '三五好友',
      form41: '團體共學',
      form42: '類學校機構',
      form43: '您的出生年次(西元)',
      form44: '您自己的年次，如1985',
      form45: '大約',
      form46: '請填出生年份',
      form47: '這是為了尋友用，如果您不願意填真實年份，可以增減1~2歲。',
      form48: '無法計算年歲',
      form49: '出生年(西元)的欄位僅能填寫數字喔',
      form50: '孩子的出生年次(西元)，若還沒有孩子或還不需找共學夥伴可略過本項',
      form51: '最大孩子的出生年次(西元)',
      form52: '若您有多位孩子，請再填寫：',
      form53: '最小孩子的出生年次(西元)',
      form54: '個人興趣',
      form55: '可分享',
      form56: '知識、服務、場地設備或物資',
      form57: '尋找',
      form58: '讓人知道可以協助你什麼?',
      form59: '可支付',
      form60: '你願意支付多少錢或換工來得到協助?',
      form61: '自我介紹',
      form62: '自我介紹很重要，請寫20字以上，謝謝',
      form63: '請再寫',
      form64: '字介紹您自己',
      form65: '愈詳細別人愈能認識您，您的旗幟才好發揮作用。',
      form66: '謝謝您',
      form67: '您詳細的介紹，讓人能夠更認識您。',
      form68: '您還有',
      form69: '必填項目尚未填寫',
      form70: '我同意自學2.0的',
      form71: '隱私權政策',
    },

    chat: {
      feedback: '意見回饋',
      gc: '群聊',
      cp: '縮小',
      submit: '留言'
    },
    news: {
      donate_us: '支持自學，發票愛心碼捐9806',
      upgrading: '自學2.0更新中',
      flag: '請定期更新您的互助旗',
      remove: '以Facebook登入之旗幟將被移除',
      //report: '任何建議與錯誤回報，請上此<a href="https://github.com/3dw/auto20-next/issues" target="_blank" rel="noopener noreferrer">議題區</a>'
    },
    groups: {
      popular_resources: '熱門資源',
      resources: '資源',
      group_name: '輸入社團名稱',
      enter_group_name: '請先輸入社團名稱',
      create_group: '創建社團',
      go_group: '前往社團',
      join_group: '我要加入',
      out_group: '我要退出',
      members: '成員',
      update_sucess: '社團更新成功',
      update_failed: '社團更新失敗',
      out_confirm: '確認您要退出社團嗎？',
    },
    group: {
      copy_link: '複製連結',
      link_copied: '連結已複製',
      members: '成員',
      save: '存檔',
      cancel: '取消',
      like: '推薦',
      likes: '人推薦',
      edit_group: '編輯社團資料',
      delete_group: '刪除該社團',
      explore_groups: '探索所有社團',
      enter_intro: '輸入社團簡介',
      enter_intro_first: '請先輸入社團簡介',
      update_intro: '更新社團簡介',
      resources_sorted: '資源(依推薦人數排序)',
      resources: '資源',
      hide_resource: '隱藏該資源',
      show_resource: '顯示該資源',
      enter_resource: '輸入資源名稱',
      enter_resource_first: '請先輸入資源名稱',
      enter_link: '輸入資源網址',
      enter_link_first: '請先輸入資源網址',
      add_resource: '新增資源',
      anything_to_say: '在想什麼嗎？',
    },
    friends: {
      recent_updates: '最近更新',
      nearest : '離我最近',
      similiar_interest: '興趣相仿',
      seeking_help: '尋求協助',
      sharer: '尋找需求者',
      random_profile: '隨機介紹',
      similar_age_kid: '孩子年齡相近',
      area: '附近',
      taipei: '台北',
      newtaipei: '新北',
      taoyuan: '桃園市',
      taichung: '臺中市',
      tainan: '臺南市',
      kaohsiung: '高雄市',
      changhua: '彰化縣',
      pingtung: '屏東縣',
      yunlin: '雲林縣',
      miaoli: '苗栗縣',
      chiayiCounty: '嘉義縣',
      hsinchuCounty: '新竹縣',
      hsinchuCity: '新竹市',
      keelung: '基隆市',
      yilan: '宜蘭縣',
      nantou: '南投縣',
      chiayiCity: '嘉義市',
      hualien: '花蓮縣',
      taitung: '臺東縣',
      penghu: '澎湖縣',
      lienchiang: '連江縣'

      //report: '任何建議與錯誤回報，請上此<a href="https://github.com/3dw/auto20-next/issues" target="_blank" rel="noopener noreferrer">議題區</a>'
    },
    about: {
      intro: ' 使用說明 ',
      what_is_auto20: '自學2.0是什麼？',
      auto20: '自學2.0',
      intro_1: '是一個公益尋友平台，免付費、無業配廣告，旨在透過地理位置、個人興趣及專長來協助用戶尋找朋友。平台的核心功能「互助旗」提供公開的自我介紹，讓人們在見面前能初步了解和認識對方。',
      al: '自學',
      intro_2: '有狹義的「在家自學」和廣義的「自主學習」。自主學習是一種為自己的成長和生命負責的態度。',
      intro_3: '自學2.0歡迎所有對「自主學習」有興趣的朋友一起參與，不分年齡、在校與否。',
      auto20_by: '自學2.0由誰在設計及維護？',
      auto20_by_content1: '本平台由非營利組織',
      auto20_by_content2: '中華民國自主學習促進會(愛心碼9806)',
      auto20_by_content3: '設計及維護，致力於提供一個安全且友善的環境，讓大家能夠自由地尋找學習夥伴。',
      card_flag: '雲端名片與互助旗是什麼？',
      card_name: '雲端名片',
      card_content1: '是一個帶有概略地理位置的自我介紹功能，讓人在與您見面之前，便能對您的興趣、專長及背景有初步的認識，從而更容易找到志同道合的朋友。',
      flag_content1: '系統會自動依據您的概略地理位置，在地圖上給予您適當的位置，像是插上一支旗子。故又稱為「互助旗」。',
      card_content2: '當您建立雲端名片之後，系統會提供您一個專屬的網址，可以用來自我介紹。',
      qrcode_content1: '您還可以得到一個專屬的QR-Code，在手機上可讓朋友掃描連結到您的雲端名片。',
      raise_flag: '升起互助旗',
      card_flag_tip: '填表升旗的小秘訣',
      card_flag_tip_content: '「興趣」、「分享」、「尋找」這三者會影響程式自動配對的邏輯運算，為了更精準地找到需求者和協助者，請清楚填寫您的興趣、分享和尋找。格式上可以用「,」「，」或「、」來分隔多項的內容。',
      flag_update1: '互助旗需更新嗎？',
      flag_update_content: '為了讓有需要的朋友能夠順利找到您，請更期更新一次您的互助旗，特別是搬家之後。',
      flag_content2: '我的互助旗會是公開的嗎？',
      flag_content3: '互助旗是完全公開的，您可以選擇只留下您願意公開的資訊。請參考我們的',
      flag_content4: '，以了解如何更好地保護您的隱私。',
      flag_update2: '升起互助旗後可以修改嗎？',
      flag_content5: '當然可以！只需再次登入平台，點選「我的旗幟」，即可隨時修改您的互助旗內容，保持信息的最新和準確。',
      flag_download: '升起互助旗後可以下載備份嗎？',
      flag_content6: '當然可以！只需再次登入平台，點選「隱私權政策」，點擊下方「下載備份」按鈕，即可下載您的資料備份',
      pr_go: '前往隱私權政策',
      flag_remove: '升起互助旗後可以移除嗎？',
      flag_content7: '可以，您只要在「我的旗幟」頁面點選「刪除旗幟」來刪除旗幟並退出自學2.0的服務。',
      map_is: '地圖介面有什麼用意？',
      map_content1: '地圖介面讓您能夠根據地理位置就近尋找夥伴。例如，您可以查看「宜蘭附近有沒有自學朋友呢？」透過地圖，您可以一目了然地找到附近的學習夥伴，增進彼此的互動和交流。',
      map_go: '前往地圖介面',
      directory_is: '名簿是什麼？',
      directory_content1: '是方便管理和查找特定朋友的功能，類似捷徑或釘選。使用者可以建立自己的名簿，方便下次查找特定的朋友。',
      group_is: '自學2.0中社團概念為何？',
      group_content1: '功能類似資源庫，使用者可創建社團，添加簡介，分享學習資源，留言聊天。這有助於根據主題收集和分享有用資訊，促進成員間的協作和互動。',
      group_go: '前往社團介面',
      bugreport_is: '如何提出錯誤回報或功能請求？',
      bugreport_content1: '有位於Github的專案議題區。請上Github的專案議題區提出錯誤回報與功能請求，謝謝。若您還沒有Github帳號，請先註冊一個Github帳號再留言。',
      bugreport_content2: 'Note: 若您單純是要登入自學2.0，而非提出議題，並不需要一個Github帳號。',
      bugreport_go: '前往專案議題區',
      github_is: 'Github平台是什麼？',
      github_content: '如果有70個人要協作一份重要文件，每個人都有不同的想法但都希望文件更好，可以把他們分成七組，每組10人。每組使用便利貼、布告板等工具集思廣益。然而，整合所有小組意見時，會發現每組都有優缺點，難以完全整合。使用Google Docs協作，當人數多時，單一版本控制常常不夠用。若只有兩三人，可以保留所有不同意見並整合到新版本。GitHub 提供一種更強大的協作平台，基於Git版本控制系統，有效解決這些問題。',
      github_go: '前往Github入門介紹',

    }, 

    privacy: {
      title: ' 隱私權政策 ',
      strong: '為了保護您的個人隱私，請不要在互助旗上放置任何敏感或過於完整的個人身份資訊。',
      content1: '歡迎您使用我們的服務！我們非常重視您的隱私權保護，特此說明自學2.0(以下簡稱本平台)的互助旗內容是完全公開的。程式並會需要您的Email以登錄，請選擇您方便公開的Email。',
      content2: '使用本服務時，請您謹慎考慮哪些資訊是您願意公開於網路上。我們鼓勵您只分享您願意讓他人知道的資訊。',
      content3: '為了您的資料安全，本平台提供了資料下載備份的選項。您可以隨時下載您的互助旗資料，以便於自行管理和備份。您也可以在登入後刪除您自己的旗幟。',
      cc_is: '創用授權',
      cc_content1: '您於本平台上的互助旗，均以創用授權CC-BY-ND公開於網路。這意味著您授權他人自由分享和傳播您的內容，但不得對其進行修改，並且必須給予適當的署名。',
      cc_content2: 'CC BY-ND授權條款的詳細說明',
      cc_content3: 'CC BY-ND（署名-禁止演繹）是一種創作共用授權條款，允許他人以任何形式和任何目的再利用您的作品，但有兩個條件：',
      cc_by_is: '署名（BY）：',
      cc_content4: '使用您的作品時，必須給予您適當的署名，提供作品的名稱，並指出是否對作品進行了修改。具體的署名方式可以由您自行決定。',
      cc_nd_is: '禁止演繹（ND）：',
      cc_content5: '使用者不能對您的作品進行任何修改，改編或以其他方式創作衍生作品。',
      cc_content6: '這意味著他人可以自由分享您的作品，但必須保持作品的完整性，並且必須按照您指定的方式進行署名。',
      security_download_is: '數據安全和下載備份',
      security_download_content1: '我們提供資料下載備份的選項，讓您可以隨時下載並管理您的互助旗資料。請定期下載備份，以確保您的資料安全。',
      manager_right_is: '管理員權利',
      manage_content1: '若遇到特殊情況，例如用戶兩年以及未更新旗幟或是出現明顯冒犯性語言等問題，自學2.0管理員保有移除互助旗之權利，但管理員仍需遵守創用授權，不得局部修改用戶之互助旗。',
      contactus: '聯絡我們',
      contact_manager: '如果您對於隱私權政策有任何疑問或建議，請隨時聯絡我們。管理員聯絡方式如下：',
      download_backup: '下載我的備份',
      lg_download_backup: '登入以下載備份',
    }
  }
