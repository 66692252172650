//en英文语言包 用雙引號取代單引號，因為所包覆的英文句子需要使用單引號，如：it's 或是重點單字都用單引號
export default {
    buttons: {
      addToDirectory: 'Add to Directory',
      removeFromDirectory: 'Remove from Directory',
      viewMap: 'View Map',
      shareCard: 'Share Card',
      showQRCode: 'Show QR Code',
    },
    contact: {
        clickableEmail: 'Clickable Email Contact:',
        contactMethod: 'Contact Method:',
        availableTime: 'Available Time:',
        personalWebsite: 'Personal Website',
        communityWebsite: 'Community Website',
    },
    card: {
      interests: 'Interests: ',
      shareable: 'Shareable: ',
      seeking: 'Seeking: ',
      payable: 'Payable: ',
    },
    login: {
      alreadyUpdated: 'already updated',
      login_into_your_account: 'Login Into Your Account',
      login_with_google: 'Login With Google',
      keep_me_logged_in: 'Keep Me Logged In',
      forgot_password: 'I forgot my password',
      or: 'or',
      small_hint: 'Hint: ',
      welcome:"Welcome to Autonomous Learning 2.0(Auto20) non-profit friend search platform",
      hp:"Home page",
      ab:"About us",
      link: 'Links',
      mp:"Map",
      fr:"Friends",
      polis: 'Disscusions',
      gp:"Groups",
      auto_gp:"Self-directed Learning Groups",
      fg:"My flag",
      hd:"Some are first in learning, and others excel in  different fields.",
      pr:" Privacy Policy ",
      bk: "My Directory",
      logout: "Logout",
      lm: "Learn more",
      login: "Login",
      login_to_see_data: 'Please login first',
      login_sucess: 'Login successfully',
      login_first: 'Please log in first',
      go_flag: "Go to My Flag",
      em: "Email: ",
      isapp: "This system does not support browsing within apps like Facebook, Line, etc. Please open the link using a standard web browser in order to log in. Thank you.",
      old: " years old",
      arround: "Around",
      raise_flag: 'Raise Flag',
      update_flag: 'Update Flag',
      raise_sucess: 'Raise Flag  Sucessfully',
      update_sucess: 'Updated Sucessfully',
      update_failed: 'Update Failed',
      check_flag: 'My Flag',
      delete_flag: 'Delete Flag',
      agree_pr: "Please check the box 'I agree to the Autonomous Learning 2.0(Auto20) Privacy Policy' first",
      delete_confirm2: "Are you sure you want to leave Autonomous Learning 2.0(Auto20) and delete your Mutual Aid Flag? Please note that this action cannot be undone.",
      delete_confirm3: "Your Mutual Aid Flag has been deleted. Thank you for using our service.",
      delete_failed: "Deletion failed.",
      anoymous: "Anonymous",
      end: "End",
      leave_messages: "Leave a message",
      source_github: "Github Source Code",
    },
    tutorial: {
      新手任務: 'Missions for Bigginer',
      任務: 'Mission',
      隱藏新手任務: 'Hide Bigginer\'s Missions',
      升起互助旗: 'Raise the Flag',
      將一位夥伴加入名簿: 'Add a Partner to the Roster',
      加入或創建一個社團: 'Join or Create a Group',
      在社團中推薦一個資源: 'Recommend a Resource in the Group',
    },    
    profile: {
      announce1: "Please read our ",
      announce2: "privacy policy",
      announce3: " first.",
      push: "",
      start: "Start",
      edit: "Edit",
      update1: "Please update immediately",
      update2: "Your data has not been updated for three months. Please update it immediately. Thank you.",
      fillform: "Please fill out the form",
      form1: "In order to help people find partners,",
      form2: "items marked with ",
      form3: " are required.",
      form4: "About me", 
      form5: "Name",
      form6: "Your name",
      form7: "Approximate address",
      form8: "The more detailed the address, the better others can recognize your location.",
      form9: "Unable to locate",
      form10: "If you have more than one residence, please fill in one, and you can write the others in your self-introduction.",
      form11: "If you are still unable to locate, please",
      form12: "use the Chrome browser",
      form13: "and try again.",
      form14: "The address is too detailed",
      form15: "This is public information. The address should be as detailed as the street level. Please do not include house numbers.",
      form16: "Manually drag the marker to your approximate location",
      form17: "Latitude and longitude coordinates",
      form18: "URL",
      form19: "Personal URL",
      form20: "Test link",
      form21: "Participating community URL",
      form22: "Community URL",
      form23: "Valid contact method",
      form24: "Publicly available methods, such as e-mail. Facebook messages are not public.",
      form25: "More available time slots",
      form26: "For example: Friday afternoon and weekends",
      form27: "Social information",
      form28: "Your identity",
      form29: "Choose a Self-directed learning identity",
      form30: "Self-directed learner",
      form31: 'Homeschooling Parent',
      form32: 'Independent Educator',
      form33: 'Homeschooling Parent+Independent Educator',
      form34: 'Self-directed learner+Independent Educator',
      form35: 'Self-directed learner+Homeschooling Parent',
      form36: 'Self-directed learner+Homeschooling Parent+Independent Educator',
      form37: 'Primary Mode of Self-directed Learning',
      form38: 'Choose a Mode of Self-directed Learning',
      form39: 'Individual',
      form40: 'With a few Freinds',
      form41: 'Group learning',
      form42: 'School-like Institution',
      form43: 'Your year of birth (A.D.)',
      form44: 'Your own birth year, such as 1985',
      form45: 'Around',
      form46: 'Please fill in your birth year.',
      form47: 'This is for friend-matching purposes. If you prefer not to disclose your actual birth year, feel free to adjust it by 1-2 years.',
      form48: 'Unable to calculate age.',
      form49: 'The birth year (AD) field can only be filled with numbers.',
      form50: "Child's Birth Year (AD). If you do not have children or do not need to find learning partners yet, you can skip this item.",
      form51: "The birth year of the oldest child (AD)",
      form52: "If you have multiple children, please fill in their information as well:",
      form53: "The birth year of the youngest child (AD)",
      form54: "Personal interests",
      form55: "Shareable",
      form56: "Knowledge, services, venues, equipment, or materials",
      form57: "Seeking",
      form58: "Open up about your needs so others can offer their assistance.",
      form59: "Payable",
      form60: "How much are you willing to pay or exchange in services to receive assistance?",
      form61: "Self-introduction",
      form62: "Introducing yourself is crucial! Please write at least 20 characters to help others get to know you. Thank you!",
      form63: 'Please write',
      form64: ' more words to introduce yourself.',
      form65: "The more detailed you are, the better others can get to know you, and the more effective your 'Mutual Aid Flag' will be.",
      form66: 'Thank you for your cooperation.',
      form67: 'Your detailed introduction allows others to get to know you better.',
      form68: 'You still have ',
      form69: 'required fields that are not filled out.',
      form70: 'I agree to',
      form71: ' the privacy policy of Autonomous Learning 2.0(Auto20)',
    },
    chat: {
      feedback: 'Feedback',
      gc: 'Goup Chat',
      cp: 'Collapse',
      submit: 'Submit'
    },
    news: {
      donate_us: 'Donate 9806 using the charity code on your receipt to support us.',
      upgrading: "We are upgrading",
      flag: "Please update your flag regularly",
      remove: "Flags that logged in with Facebook will be removed",
      // report: 'For any suggestions and bug reports, please go to this <a href="https://github.com/3dw/auto20-next/issues" target="_blank" rel="noopener noreferrer">issue area</a>'
    },
    groups: {
      popular_resources: 'Popular Resources',
      resources: 'Resources',
      group_name: "Group Name",
      enter_group_name: "Enter Group Name",
      create_group: "Start a Group",
      go_group: "Go to Group",
      join_group: "Join Group",
      out_group: "Leave Group",
      members: "Members",
      update_sucess: "Group Updated Sucessfully",
      update_failed: "Group Updated Failed",
      out_confirm: "Are you sure you want to leave the group?",
    },
    group: {
      copy_link: 'Copy Link',
      link_copied: 'Link Copied',
      members: 'Members',
      save: 'Save',
      cancel: 'Cancel',
      like: "Like",
      likes: "Likes",
      edit_group: " Group Editing",
      delete_group: 'Detele This Group',
      explore_groups: "Explore All Groups",
      enter_intro: "Enter Group Introduction",
      enter_intro_first: "Please enter the group introduction first",
      update_intro: "Update Group Introduction",
      resources_sorted: 'Resources(sorted by likes)',
      resources: "Resources",
      hide_resource: 'Hide Resource',
      show_resource: 'Show Resource',
      enter_resource: "Enter Resource",
      enter_resource_first: "Please enter the resource first",
      enter_link: "Enter Link",
      enter_link_first: "Please enter the link of resource first",
      add_resource: "Add Resource",
      anything_to_say: "Anything to say?",
    }, 
    friends: {
      recent_updates: "Recent Updates",
      nearest: "Near Me",
      similiar_interest: 'Similar Interests',
      seeking_help: "Seeking Help",
      sharer: "Sharer",
      random_profile: "Random Profiles",
      similiar_age_kid: "Children of Similar Age",
      area: " Area",
      taipei: "Taipei",
      newtaipei: 'New Taipei',
      taoyuan: 'Taoyuan',
      taichung: 'Taichung',
      tainan: 'Tainan',
      kaohsiung: 'Kaohsiung',
      changhua: 'Changhua',
      pingtung: 'Pingtung',
      yunlin: 'Yunlin',
      miaoli: 'Miaoli',
      chiayiCounty: 'Chiayi County',
      hsinchuCounty: 'Hsinchu County',
      hsinchuCity: 'Hsinchu City',
      keelung: 'Keelung',
      yilan: 'Yilan',
      nantou: 'Nantou',
      chiayiCity: 'Chiayi City',
      hualien: 'Hualien',
      taitung: 'Taitung',
      penghu: 'Penghu',
      lienchiang: 'Lienchiang'

      //report: '任何建議與錯誤回報，請上此<a href="https://github.com/3dw/auto20-next/issues" target="_blank" rel="noopener noreferrer">議題區</a>'
    },
    about: {
      intro: " Introduction ",
      what_is_auto20: "What is Autonomous Learning 2.0(Auto20)?",
      auto20: "Autonomous Learning 2.0(Auto20)",
      intro_1: " is a public welfare friend-finding platform, free of charge and without advertising. It aims to help users find friends through geographical location, personal interests and expertise. The core function of the platform, 'Mutual Aid Flag', provides public self-introduction, allowing people to get to know each other initially before meeting.",
      al: "Autonomous Learning",
      intro_2: " is 'homeschooling' in the narrow sense and 'Self-directed learning' in the broad sense. Independent learning is an attitude of being responsible for one's own growth and life.",
      intro_3: "We welcome all friends who are interested in 'Self-directed Learning' to participate, regardless of age and whether they are in school or not.",
      auto20_by: "Who is designing and maintaining Autonomous Learning 2.0(Auto20)？",
      auto20_by_content1: "This platform is designed and maintained by",
      auto20_by_content2: " the non-profit organization Autonomous Learning Promotion Association of R.O.C. (Taiwan) (charity code: 9806)",
      auto20_by_content3: ", dedicated to providing a safe and friendly environment for everyone to freely find learning partners.",
      card_flag: "What is Cloud Card and Mutual Aid Flag?",
      card_name: "Cloud Card",
      card_content1: " is a self-introduction feature with an approximate geographical location, allowing others to have a preliminary understanding of your interests, expertise, and background before meeting you, making it easier to find like-minded friends.",
      flag_content1: "The system automatically assigns you an appropriate location on the map based on your approximate geographic location, like planting a flag. Hence, it is also called a 'Mutual Aid Flag'.",
      card_content2: "After you create a 'Cloud Card', the system will provide you with a unique URL that you can use for self-introduction.",
      qrcode_content1: "You will also receive a unique QR code that friends can scan with their mobile phones to link to your cloud card.",
      raise_flag: "Raise a Mutual Aid Flag",
      card_flag_tip: "Tips for Filling Out the Form to Raise the Flag",
      card_flag_tip_content: "'Interests', 'Sharing', and 'Seeking' are the three factors that influence the logical operation of the automatic matching program.To accurately match those in need with those who can help, please clearly fill in your interests, what you can share, and what you are seeking. You can use commas (','), Chinese commas ('，'), or slashes ('/') to separate multiple items.",
      flag_update1: "Does the 'Mutual Aid Flag' need to be updated?",
      flag_update_content: "To ensure that friends in need can find you smoothly, please update your 'Mutual Aid Flag' periodically, especially after moving.",
      flag_content2: "Will my 'Mutual Aid Flag' be public?",
      flag_content3: "The 'Mutual Aid Flag' is completely public, and you can choose to leave only the information you are willing to disclose. Please refer to our",
      flag_content4: " to learn how to better protect your privacy.",
      flag_update2: "Can I modify the 'Mutual Aid Flag' after raising it?",
      flag_content5: "Of course! Just log in to the platform again, click on 'My Flag', and you can modify the content of your 'Mutual Aid Flag' at any time to keep the information up-to-date and accurate.",
      flag_download: "Can I download a backup after raising the 'Mutual Aid Flag'?",
      flag_content6: "Of course! Just log in to the platform again, click on 'Privacy Policy', and click the 'Download Backup' button below to download your data backup.",
      pr_go: "Go to Privacy Policy",
      flag_remove: "Can I remove the 'Mutual Aid Flag' after raising it?",
      flag_content7: "Yes, you can. Simply go to the 'My Flag' page and click 'Delete Flag' to remove your flag and opt out Autonomous Learning 2.0(Auto20).",
      map_is: "What is the purpose of the 'Map'?",
      map_content1: "The 'Map' is designed to help you find friends who are geographically close to you. You can click on the flag icon on the map to view the 'Cloud Card' of the friend.",
      map_go: "Go to Map",
      directory_is: "What is the 'My Directory'?",
      directory_content1: " is a feature that allows you to manage and find specific friends easily, similar to shortcuts or pins. Users can create their own directory to easily find specific friends next time.",
      group_is: "What is the concept of 'Groups' in Autonomous Learning 2.0(Auto20)?",
      group_content1: " is similar to a resource pool. Users can create groups, add introductions, share learning resources, and chat. This helps to collect and share useful information according to the theme, and promote collaboration and interaction among members.",
      group_go: "Go to Groups",
      bugreport_is: "How to submit bug reports or feature requests?",
      bugreport_content1: " has a project issue tracker on Github. Please submit bug reports and feature requests on Github's project issue tracker. Thank you. If you don't have a Github account yet, please register a Github account first before leaving a message.",
      bugreport_content2: "Note: You do not need a Github account if you are only logging into Autonomous Learning 2.0(Auto20) and not submitting an issue.",
      bugreport_go: "Go to Project Issue Tracker",
      github_is: "What is Github?",
      github_content: "Github is a platform for hosting and collaborating on software development projects. It is widely used for version control using Git. It also provides a platform for users to submit bug reports and feature requests.",
      github_go: "GitHub Tutorial for Beginners",
    },
    privacy: {
      title: " Privacy Policy ",
      strong: "In order to protect your personal privacy, please do not place any sensitive or too complete personally identifiable information on the mutual aid flag.",
      content1: "Welcome to our service! We value your privacy and would like to inform you that the 'Mutual Aid Flag' content on Autonomous Learning 2.0(Auto20)(hereinafter referred to as the Platform) is completely public. The program also requires your email for login, please choose an email that you are comfortable making public.",
      content2: "When using this service, please carefully consider what information you are willing to make public online. We encourage you to only share information that you are comfortable having others know.",
      content3: "For the security of your data, the Platform provides an option to download a backup of your data. You can download your 'Mutual Aid Flag' data at any time for self-management and backup. You can also delete your own flag after logging in.",
      cc_is: "Creative Commons Licenses",
      cc_content1: "Your 'Mutual Aid Flag' on this platform are publicly available on the internet under the Creative Commons Attribution-NoDerivatives (CC BY-ND) license. This means you grant others permission to freely share and distribute your content, but they may not modify it and must give you appropriate credit.",
      cc_content2: "Details of the CC BY-ND license terms",
      cc_content3: "CC BY-ND (Attribution-NoDerivatives) is a Creative Commons license that allows others to reuse your work for any purpose and in any form, but with two conditions:",
      cc_by_is: "Attribution (BY):",
      cc_content4: " When using your work, they must give you appropriate credit, provide the title of the work, and indicate if any changes were made. The specific manner of attribution can be determined by you.",
      cc_nd_is: "NoDerivatives (ND):",
      cc_content5: "Users cannot make any modifications, adaptations, or create derivative works from your work in any way.",
      cc_content6: "This means that others can freely share your work, but they must maintain the integrity of the work and must attribute it in the way you specify.",
      security_download_is: "Data Security and Backup Downloads",
      security_download_content1: "We provide an option to download data backups so you can download and manage your 'Mutual Aid Flag' data at any time. Please download backups regularly to ensure the security of your data.",
      manager_right_is: "Administrator Rights",
      manage_content1: "In the event of special circumstances, such as a user who has not updated their flag for two years or has posted offensive language, the administrators of Autonomous Learning 2.0(Auto20) reserve the right to remove the Mutual Aid Flag, but the administrators must still comply with the Creative Commons license and may not modify the user's Mutual Aid Flag.",
      contactus: "Contact Us",
      contact_manager: "If you have any questions or suggestions regarding the Privacy Policy, please feel free to contact us. The contact information for the administrators is as follows:",
      download_backup: "Download My Backup",
      lg_download_backup: "Login to download backup",
    }
  }